import React, { useState, useEffect } from "react"
import Head from "@components/Head"
import MainLayout from "@components/main_layout"
import {
  Typography,
  Form,
  Input,
  Button,
  Table,
  Space,
  Modal,
  Spin,
} from "antd"
import { Link } from "gatsby"
import { SearchOutlined, CheckOutlined } from "@ant-design/icons"
import { transformDate } from "@action"
import {
  AppraisalClient,
  OtherClient,
  TemplateType,
  AppraisalCycleClient,
} from "@api"
import { FormattedMessage, useIntl } from "react-intl"
import { SearchContent } from "@components/SearchContent"
import PrintButton from "@components/PrintButton"
import { InactiveTag, ScoreProgressCircle } from "lib"

const CommentModal = ({ visible, onCancel, selectedKeys, refresh }) => {
  const { TextArea } = Input
  const [form] = Form.useForm()
  const { formatMessage } = useIntl()
  const [isLoading, setIsLoading] = useState(false)
  const handleSubmit = async comment => {
    setIsLoading(true)
    try {
      let client = new AppraisalClient()
      await client.batchApprove(comment)
      onCancel()
      refresh()
    } catch (err) {
      console.log(err)
    } finally {
      setIsLoading(false)
    }
  }

  const textareaPlaceholder = formatMessage({ id: "textAreaReviewComment" })

  const onFinish = values => {
    setIsLoading(true)
    handleSubmit({ ...values, id: selectedKeys })
  }
  return (
    <Modal
      forceRender
      title={<FormattedMessage id="comment" />}
      visible={visible}
      okText={<FormattedMessage id="approve" />}
      cancelText={<FormattedMessage id="cancel" />}
      onCancel={onCancel}
      onOk={() => form.submit()}
      confirmLoading={isLoading}
    >
      <Form form={form} onFinish={onFinish}>
        <Form.Item name="comment">
          <TextArea
            placeholder={textareaPlaceholder}
            autoSize={{ minRows: 4 }}
          />
        </Form.Item>
      </Form>
    </Modal>
  )
}

const MyTaskTable = () => {
  const [visible, setVisible] = useState(false)
  const [dataSource, setDataSource] = useState([])
  const [pageMeta, setPageMeta] = useState({})
  const [selectedKeys, setSelectedKeys] = useState([])
  const [canApprove, setCanApprove] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [searchParams, setSearchParams] = useState({
    cycleId: null,
    appraisalNumber: null,
    staffNo: null,
    staffName: null,
    templateType: null,
    siteId: null,
    positionId: null,
    orderBy: "appraisalNumber",
    isAscend: false,
    pageSize: 10,
    pageIndex: 1,
    isStaffInactiveExcluded: false,
    isStaffActiveExcluded: false,
    isStaffDisabledExcluded: false,
  })
  const [options, setOptions] = useState(null)

  const getData = async params => {
    setIsLoading(true)
    try {
      let client = new AppraisalClient()
      let res = await client.get(
        params.cycleId,
        true,
        params.appraisalNumber,
        params.staffNo,
        params.staffName,
        params.templateType,
        params.siteId,
        params.positionId,
        params.orderBy,
        params.isAscend,
        params.pageSize,
        params.pageIndex,
        false, //frontline
        null,
        null,
        params.isStaffInactiveExcluded,
        params.isStaffActiveExcluded,
        params.isStaffDisabledExcluded
      )
      setDataSource(res.items)
      setCanApprove(res.items.findIndex(item => item.hasBatchApprove) !== -1)
      setPageMeta({
        current: res.pageIndex,
        total: res.totalCount,
        pageSize: params.pageSize,
      })
    } catch (err) {
      console.log(err)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    const getOptions = async () => {
      try {
        const client = new OtherClient()
        const cycleClient = new AppraisalCycleClient()
        const res = await client.get()
        const cycleList = await cycleClient.getDropdown()
        setOptions({ ...res.content, cycleList: cycleList })
      } catch (err) {
        console.log(err)
      }
    }

    getOptions()
  }, [])

  useEffect(() => {
    getData(searchParams)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams])

  const columns = [
    {
      title: <FormattedMessage id="appraisalNo" />,
      dataIndex: "appraisalNumber",
      sorter: true,
    },
    {
      title: <FormattedMessage id="appraisalCycleName" />,
      dataIndex: "appraisalCycleName",
      sorter: true,
    },
    {
      title: <FormattedMessage id="appraiseeName" />,
      dataIndex: "staffName",
      sorter: true,
    },
    {
      title: <FormattedMessage id="staffStatus" />,
      dataIndex: "isStaffEnabled",
      render: (value, { disableRemarks }) => (
        <InactiveTag value={value} remarks={disableRemarks} />
      ),
      sorter: true,
    },
    {
      title: <FormattedMessage id="position" />,
      dataIndex: "position",
      sorter: true,
      render: text => (text ? text : " - "),
    },
    {
      title: <FormattedMessage id="site" />,
      dataIndex: "siteOrDepartment",
      sorter: true,
    },

    {
      title: <FormattedMessage id="status" />,
      dataIndex: "status",
      sorter: true,
    },
    {
      title: <FormattedMessage id="appraisalPeriod" />,
      dataIndex: "appraisalStartDate",
      sorter: true,
      render: (_, record) => {
        return (
          transformDate(record.appraisalStartDate) +
          " to " +
          transformDate(record.appraisalEndDate)
        )
      },
    },

    {
      title: <FormattedMessage id="finalScore2" values={{ name: "" }} />,
      dataIndex: "finalScore",
      sorter: true,
      render: (finalScore, { scoreJSON, id }) => (
        <ScoreProgressCircle
          finalScore={finalScore}
          scoreJSON={scoreJSON}
          appraisalId={id}
        />
      ),
    },

    {
      title: <FormattedMessage id="action" />,
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          {![TemplateType.Degree360, TemplateType.Frontline].includes(
            record.templateType
          ) && <PrintButton id={record.id} type="default" size="small" />}
          <Link to={`/appraisal/${record.id}`}>
            <Button shape="round" icon={<SearchOutlined />} size="small">
              <FormattedMessage id="view" />
            </Button>
          </Link>
        </Space>
      ),
    },
  ]

  const handleTableChange = (pagination, b, sorter, { action }) => {
    if (action === "paginate") {
      setSearchParams({
        ...searchParams,
        pageIndex: pagination.current,
        pageSize: pagination.pageSize,
      })
    } else if (action === "sort") {
      setSearchParams({
        ...searchParams,
        orderBy: sorter.field,
        isAscend: sorter.order === "ascend",
      })
    }
  }

  const rowSelection = {
    hideSelectAll: true,
    onChange: (selectedRowKeys, _) => {
      setSelectedKeys(selectedRowKeys)
    },
    renderCell: (_, record, index, originNode) =>
      record.hasBatchApprove && originNode,
  }

  return options ? (
    <>
      <CommentModal
        refresh={() => getData(searchParams)}
        visible={visible}
        onCancel={() => {
          setVisible(false)
        }}
        selectedKeys={selectedKeys}
        setIsLoading={isLoading => setIsLoading(isLoading)}
      />
      <SearchContent
        setSearchParams={values => setSearchParams(values)}
        options={options}
      />
      {canApprove && (
        <Button
          shape="round"
          type="primary"
          icon={<CheckOutlined />}
          disabled={selectedKeys.length < 1}
          onClick={() => {
            setVisible(true)
          }}
          style={{ margin: "5px" }}
        >
          <FormattedMessage id="approve" />
        </Button>
      )}
      <Spin spinning={isLoading} size="large">
        <Table
          rowKey="id"
          size="small"
          className="table"
          dataSource={dataSource}
          columns={columns}
          onChange={handleTableChange}
          scroll={{ x: true }}
          pagination={{
            ...pageMeta,
            position: ["bottomCenter"],
          }}
          rowSelection={{ ...rowSelection }}
        />
      </Spin>
    </>
  ) : (
    <Spin size="large" />
  )
}

const IndexPage = () => {
  const { Title } = Typography

  return (
    <MainLayout>
      <Head id="myTaskTitle" />
      <Title level={3}>
        <FormattedMessage id="myTaskTitle" />
      </Title>
      <MyTaskTable />
    </MainLayout>
  )
}

export default IndexPage
