import React from "react"
import { useIntl } from "react-intl"
import { Button } from "antd"
import { PrinterOutlined } from "@ant-design/icons"

const PrintButton = ({ id, type = "primary", size = "default" }) => {
  const { formatMessage } = useIntl()
  const printWord = formatMessage({ id: "print" })

  return (
    <a href={"/PdfReport/" + id} target="_blank" rel="noreferrer noopener">
      <Button icon={<PrinterOutlined />} shape="round" type={type} size={size}>
        {printWord}
      </Button>
    </a>
  )
}

export default PrintButton
