import { FormattedMessage, useIntl } from "react-intl"
import React from "react"
import { Form, Input, Select, Row, Col, Button, Grid } from "antd"
import "./SearchContent.less"
import { TemplateType } from "@api"

const SearchContent = ({ setSearchParams, options, isSearch = false }) => {
  const colProps = { xs: 12 }
  const { xl } = Grid.useBreakpoint()
  const { Option } = Select
  const [form] = Form.useForm()
  const { formatMessage } = useIntl()
  const onFinish = values => {
    let isStaffActiveExcluded = false
    let isStaffDisabledExcluded = false
    let isStaffInactiveExcluded = false
    if (values.staffStatus !== null) {
      if (values.staffStatus === "active") {
        isStaffActiveExcluded = false
        isStaffDisabledExcluded = true
        isStaffInactiveExcluded = true
      }
      if (values.staffStatus === "inactive") {
        isStaffInactiveExcluded = false
        isStaffActiveExcluded = true
        isStaffDisabledExcluded = true
      }
      if (values.staffStatus === "terminated") {
        isStaffDisabledExcluded = false
        isStaffActiveExcluded = true
        isStaffInactiveExcluded = true
      }
    }
    setSearchParams({
      cycleId: values.cycleId,
      appraisalNumber: values.appraisalNumber,
      staffNo: values.staffNo,
      staffName: values.staffName,
      templateType: values.templateType,
      siteId: values.siteId,
      status: values.status,
      positionId: values.positionId,
      pageIndex: 1,
      pageSize: 10,
      orderBy: "appraisalNumber",
      isAscend: true,
      isStaffInactiveExcluded: isStaffInactiveExcluded,
      isStaffActiveExcluded: isStaffActiveExcluded,
      isStaffDisabledExcluded: isStaffDisabledExcluded,
    })
  }

  const staffNoPlaceholder = formatMessage(
    { id: "inputPlaceholder" },
    { name: formatMessage({ id: "staffNo" }) }
  )
  const staffNamePlaceholder = formatMessage(
    { id: "inputPlaceholder" },
    { name: formatMessage({ id: "staffName" }) }
  )
  const typePlaceholder = formatMessage(
    { id: "selectPlaceholder" },
    { name: formatMessage({ id: "appraisalType" }) }
  )
  const sitePlaceholder = formatMessage(
    { id: "selectPlaceholder" },
    { name: formatMessage({ id: "site" }) }
  )
  const positionPlaceholder = formatMessage(
    { id: "selectPlaceholder" },
    { name: formatMessage({ id: "position" }) }
  )
  const statusPlaceholder = formatMessage(
    { id: "selectPlaceholder" },
    { name: formatMessage({ id: "status" }) }
  )

  const cyclePlaceholder = formatMessage(
    { id: "selectPlaceholder" },
    { name: formatMessage({ id: "reviewPeriod" }) }
  )

  const employmentStatusPlaceholder = formatMessage(
    { id: "selectPlaceholder" },
    { name: formatMessage({ id: "staffStatus" }) }
  )

  const nonManagerial = formatMessage({ id: "nonManagerial" })

  const managerial = formatMessage({ id: "managerial" })

  const frontline = formatMessage({ id: "frontline" })

  const degree360 = formatMessage({ id: "degree360" })

  return (
    <Form
      layout={xl ? "inline" : "vertical"}
      onFinish={onFinish}
      preserve={true}
      form={form}
    >
      <Row gutter="20" className="search-form">
        <Col {...colProps}>
          <Form.Item name="staffNo" label={<FormattedMessage id="staffNo" />}>
            <Input placeholder={staffNoPlaceholder} allowClear />
          </Form.Item>
        </Col>

        <Col {...colProps}>
          <Form.Item
            name="staffName"
            label={<FormattedMessage id="staffName" />}
          >
            <Input placeholder={staffNamePlaceholder} allowClear />
          </Form.Item>
        </Col>

        <Col {...colProps}>
          <Form.Item
            name="templateType"
            label={<FormattedMessage id="appraisalType" />}
          >
            <Select
              allowClear
              placeholder={typePlaceholder}
              mode="multiple"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option value={TemplateType.Managerial}>{managerial}</Option>
              <Option value={TemplateType.NonManagerial}>
                {nonManagerial}
              </Option>
              {isSearch && (
                <Option value={TemplateType.Frontline}>{frontline}</Option>
              )}
              {!isSearch && (
                <Option value={TemplateType.Degree360}>{degree360}</Option>
              )}
            </Select>
          </Form.Item>
        </Col>

        <Col {...colProps}>
          <Form.Item name="siteId" label={<FormattedMessage id="site" />}>
            <Select
              allowClear
              placeholder={sitePlaceholder}
              mode="multiple"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {options.siteOrDepartmentList.map(item => (
                <Option key={item.id} value={item.id}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col {...colProps}>
          <Form.Item
            name="positionId"
            label={<FormattedMessage id="position" />}
          >
            <Select
              allowClear
              placeholder={positionPlaceholder}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              mode="multiple"
            >
              {options.positionList.map(item => (
                <Option key={item.id} value={item.id}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col {...colProps}>
          <Form.Item
            name="cycleId"
            label={<FormattedMessage id="reviewPeriod" />}
          >
            <Select
              allowClear
              placeholder={cyclePlaceholder}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {options.cycleList.map(item => (
                <Option key={item.id} value={item.id}>
                  {item.code}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col {...colProps}>
          <Form.Item
            name="staffStatus"
            label={<FormattedMessage id="staffStatus" />}
          >
            <Select allowClear placeholder={employmentStatusPlaceholder}>
              <Option key="active" value="active">
                <FormattedMessage id="active" />
              </Option>
              <Option key={"inactive"} value="inactive">
                <FormattedMessage id="inactive" />
              </Option>
              <Option key="terminated" value="terminated">
                <FormattedMessage id="terminated" />
              </Option>
            </Select>
          </Form.Item>
        </Col>

        {isSearch ? (
          <Col {...colProps}>
            <Form.Item name="status" label={<FormattedMessage id="status" />}>
              <Select allowClear placeholder={statusPlaceholder}>
                {options.status.map(item => (
                  <Option key={item} value={item}>
                    {item}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        ) : null}

        <Col xs={24} className="form-buttons">
          <Row justify="center">
            <Col xs={6} lg={3}>
              <Button block type="primary" shape="round" htmlType="submit">
                <FormattedMessage id="search" />
              </Button>
            </Col>
            <Col xs={6} lg={3} offset={1}>
              <Button
                block
                type="ghost"
                shape="round"
                onClick={() => {
                  form.resetFields()
                  form.submit()
                }}
              >
                <FormattedMessage id="clear" />
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  )
}

export default SearchContent
